import "./App.css";
import React from "react";
import Headroom from "react-headroom";

export default class Header extends React.Component {
  render() {
    return (
      <Headroom>
        <header
          className="header-global"
          id="home"
          style={{ backgroundColor: "#fff" }}
        >
          <nav
            id="navbar-main"
            aria-label="Primary navigation"
            className="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom @@classNamees"
          >
            <div className="container position-relative">
              <a className="navbar-brand mr-lg-4" href="@@path/index.html">
                <img
                  className="navbar-brand-dark round-icon shadow"
                  src="/assets/img/light.svg"
                  alt="Logo light"
                />
              </a>
              <div
                className="navbar-collapse collapse mr-auto"
                id="navbar_global"
              >
                <div className="navbar-collapse-header">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      <a href="@@path/index.html">
                        <img
                          src="@@path/assets/img/dark.svg"
                          alt="Logo dark"
                          className="shadow"
                        />
                      </a>
                    </div>
                    <div className="col-6 collapse-close">
                      <a
                        href="#navbar_global"
                        className="fas fa-times"
                        data-toggle="collapse"
                        data-target="#navbar_global"
                        aria-controls="navbar_global"
                        aria-expanded="false"
                        title="close"
                        aria-label="Toggle navigation"
                      ></a>
                    </div>
                  </div>
                </div>
                <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                  <li className="nav-item">
                    <a href="#about" className="nav-link">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#testimonials" className="nav-link">
                      Testimonials
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#download" className="nav-link">
                      Download
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center">
                <a
                  href="https://apps.apple.com/us/app/highlights-cover-maker/id1459962627#?platform=iphone"
                  target="_blank"
                  className="btn btn-outline-dark d-none d-md-inline mr-md-3 animate-up-2"
                >
                  Free download
                </a>

                <button
                  className="navbar-toggler ml-2"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar_global"
                  aria-controls="navbar_global"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
          </nav>
        </header>
      </Headroom>
    );
  }
}
