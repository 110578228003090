import "./App.css";
import { Route, Routes } from "react-router-dom";
import React from "react";
import Linkinbio from "./Linkinbio";
import Landing from "./Landing";
import BioText from "./containers/BioText";
import MapMain from "./containers/map/MapMain";

function App() {
  return (
    <Routes>
      <Route
        path="/map_test/inapp_preview/:username/"
        element={<MapMain previewMode={true} />}
      ></Route>
      <Route path="/map_test/:username/" element={<MapMain />}></Route>
      <Route path="/globe_app/:username/" element={<MapMain />}></Route>

      <Route path="/instagram_fonts" element={<BioText />}></Route>
      <Route path="/:username" element={<Linkinbio />}></Route>
      <Route path="/" element={<Landing />}></Route>
    </Routes>
  );
}

export default App;
