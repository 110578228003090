import logo from "./logo.svg";
import React from "react";
import axios from "axios";
import { withParams } from "./hocs";
import { COLORS } from "./_style/colors";
import Lottie from "react-lottie-player";
import * as loadingLottie from "./lottie/loading-animation-gradient-line.json";
import * as notFoundLottie from "./lottie/10687-not-found.json";

class Linkinbio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: false, loading: true };
  }

  componentDidMount() {
    let username = this.props?.params?.username || "";
    if (username.length > 1) {
      axios
        .get(
          "https://hcm-cs-backend.herokuapp.com/api/bio/" +
            username.replace("/", "")
        )
        .then((res) => {
          this.setState({ user: res.data, loading: false });
          document.title = res.data?.name
            ? res.data?.name + "'s Blip Bio"
            : "Blip Bio not found";

          if (res.data && res.data?._id) {
            axios.post(
              "https://hcm-cs-backend.herokuapp.com/api/events/registerViewBiolink",
              { id: res.data._id }
            );
          }
        });
    }
  }

  render() {
    var is_light_mode = this.state.user?.theme_config_v1?.is_light_mode;
    return (
      <React.Fragment>
        <div
          id={"body_link_in_bio"}
          style={{
            minHeight: "100vh",
            backgroundColor:
              this.state.user?.theme_config_v1?.backgroundColor || "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            textAlign: "center",
          }}
        >
          {/* <RWebShare
            data={{
              text: "Check out " + this.state.user.name + "'s links",
              url: "https://on.natgeo.com/2zHaNup",
              title: this.state.user.name,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <div
              style={{
                height: 35,
                width: 35,
                position: "fixed",
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                top: 50,
                right: 50,
                boxShadow: "0 .5rem 1rem rgba(0,0,0,.05)",
                paddingTop: 5,
              }}
            >
              <i className="fas fa-share-alt " style={{ margin: "0 auto" }}></i>
            </div>
          </RWebShare> */}
          <div
            style={{
              height: 120,
              background: this.state.user.cover_picture
                ? "url('" + this.state.user.cover_picture + "')"
                : "rgba(0,0,0,0.03)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={50}
              width={"100%"}
              style={{
                position: "absolute",
                top: 75,
                left: 0,
                right: 0,
                zIndex: 0,
                border: "none",
              }}
              fill={
                this.state.user?.theme_config_v1?.backgroundColor || "#f5f5f5"
              }
            >
              <ellipse cx="50%" cy="50" rx="51%" ry="35" />
            </svg>
          </div>

          {this.state.loading ? (
            <Lottie
              animationData={loadingLottie}
              play
              loop
              style={{ width: 100, height: 100, alignSelf: "center" }}
            />
          ) : this.state.user ? (
            <div
              className="col-lg-6 col-md-12 col-sm-12 col-12 mx-auto"
              style={{ zIndex: 1 }}
            >
              <div
                style={{
                  margin: 13,
                  marginTop: -75,
                  borderRadius: 20,
                  padding: 13,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    borderRadius: 5000,
                    backgroundColor:
                      this.state.user?.theme_config_v1?.backgroundColor ||
                      "#f5f5f5",
                    flex: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    borderWidth: 15,
                    padding: 10,
                  }}
                >
                  <img
                    src={this.state.user.profile_picture}
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: 120,
                      backgroundColor: "#fff",
                      objectFit: "cover",
                      borderWidth: 15,
                      border: "10px solid #fff",
                      boxShadow: "0 .5rem 0.5rem rgba(0,0,0,.05)",
                    }}
                  />
                </div>
                <div style={{ marginTop: 21, marginBottom: 21 }}>
                  <h1
                    className="display-4 font-weight-bolder my-2"
                    style={{
                      color: is_light_mode ? COLORS.grey_5 : "#fff",
                    }}
                  >
                    {this.state.user.name || "Edit your page on Blip"}
                  </h1>
                  <h6
                    className="font-weight-bolder my-2"
                    style={{
                      color: is_light_mode ? COLORS.grey_5 : "#fff",
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    {this.state.user.bio}
                  </h6>
                </div>
                {(this.state.user?.links_list || []).map((item) => (
                  <a href={item.link} style={{ textDecoration: "none" }}>
                    <div
                      className="hvr-grow"
                      style={{
                        backgroundColor: is_light_mode
                          ? "rgba(255,255,255,1)"
                          : "rgba(0,0,0,0.8)",
                        boxShadow: "0 .5rem 1rem rgba(0,0,0,.05)",
                        marginBottom: 13,
                        borderRadius: 2,
                        paddingTop: 25,
                        paddingBottom: 5,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                    >
                      <p
                        style={{
                          color: is_light_mode ? COLORS.grey_5 : "#fff",
                          textDecorationLine: "none",
                          fontWeight: "600",
                        }}
                      >
                        {item.title || item.link.replace("https://", "")}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
              <p
                style={{
                  fontWeight: "500",
                  color: "rgba(0,0,0,0.2)",
                  fontSize: 14,
                }}
              >
                Made with {"♥"} {"  "}by{" "}
                <a
                  style={{ color: COLORS.grey_4, fontWeight: "700" }}
                  href="https://apps.apple.com/us/app/highlights-cover-maker/id1459962627#?platform=iphone"
                >
                  Blip
                </a>{" "}
                on iOS
              </p>
            </div>
          ) : (
            <>
              <Lottie
                animationData={notFoundLottie}
                play
                loop
                style={{ width: 300, height: 300 }}
              />
              <h2 style={{ padding: 10, marginTop: -30 }}>
                Oops, this page got Bliped. It doesn't exist.
              </h2>
            </>
          )}
        </div>
        <div></div>
      </React.Fragment>
    );
  }
}

export default withParams(Linkinbio);
