import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-google-charts";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import withRouter from "../../withRouter";
import "./MapMain.css";
import axios from "axios";
import { like_format_helper } from "../../_helpers/likeFormat";

export default withRouter(
  class MapMain extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        input_text: "",
        name: "",
        countryList: [["Country"]],
        mapLikeCounter: 0,
        clicked: false,
      };
      // Assurez-vous de binder la méthode à la classe pour qu'elle puisse accéder à this correctement
      this.onPressFunction = this.onPressFunction.bind(this);
    }

    componentDidMount() {
      document.title = "Map Travel";
      // get username from route params react router

      var username = this.props.router.params.username;
      // query server GET user/mapinfo/myurl

      fetch(
        "https://map-travel-api.herokuapp.com/api/users/mapinfo/" + username,
        {
          method: "GET",
          headers: {},
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // this.setState({ input_text: data });

          var countries_selection = data?.countries_selection || [];
          countries_selection = [["Country"]].concat(countries_selection);
          this.setState({
            //user Info
            username: username,
            isPremium: data?.isPremium,
            mapLikeCounter: data?.mapLikeCounter || 0,
            tiktokUsername: data?.userSocials?.tiktok || "",
            instagramUsername: data?.userSocials?.instagram || "",
            youtubeUsername: data?.userSocials?.youtube || "",
            snapchatUsername: data?.userSocials?.snapchat || "",
            pinterestUsername: data?.userSocials?.pinterest || "",

            //country listing
            countryList: countries_selection?.map((country) => {
              return [country?.toString()];
            }),

            //UserCard Custo
            userCardTitle: data?.user_card_title || "My Travels",
            userCardTitleColor: data?.user_card_color?.titleColor || "#FFFFFF",
            userCardNameColor: data?.user_card_color?.nameColor || "#FFFFFF",
            userCardColor1: data?.user_card_color?.color1 || "#000000",
            userCardColor2:
              data?.user_card_color?.type === "gradient"
                ? data?.user_card_color?.color2 || "#000000"
                : data?.user_card_color?.color1 || "#000000",
            userCardIsPremium: data?.user_card_color?.isPremium || false,

            //Map Custo
            backgroundColor: data?.map_custo?.backgroundColor || "#424E6A",
            seenColor: data?.map_custo?.countries_color || "#C2D4E2",
            unseenColor: data?.map_custo?.unseenColor || "#FAFAFA",
            customName: data?.map_custo?.title || "title",
            mapCustoIsPremium: data?.map_custo?.isPremium || false,
          });
          //console.log(data?.userSocials)
          //console.log(countries_selection?.map((country) => [country]));
        });
    }

    onPressFunction = () => {
      var username = this.props.router.params.username;
      if (this.state.clicked === false) {
        // User can like
        var newLikeNumber = this.state.mapLikeCounter + 1;
        this.setState({
          mapLikeCounter: newLikeNumber,
          clicked: !this.state.clicked,
        });
        //
        axios
          .post(
            "https://map-travel-api.herokuapp.com/api/users/maplike/" +
              username,
            {
              method: "POST",
              headers: {},
            }
          )
          .then((response) => {
            // Gérer la réponse du serveur ici
            console.log(response);
          })
          .catch((error) => {
            // Gérer les erreurs ici
            console.error(error);
          });
        console.log("Map has been liked!");
      } else {
        console.log("Map has already been liked!");
      }
    };

    onSocialClick = (socialName) => {
      console.log("Social clicked :" + socialName);
      switch (socialName) {
        case "TikTok":
          console.log(this.state.tiktokUsername);
          if (this.state.tiktokUsername != null) {
            //window.location.href = "https://www.tiktok.com/@" + this.state.tiktokUsername;
            var url = "https://www.tiktok.com/@" + this.state.tiktokUsername;
            window.open(url, "_blank");
          }
          break;
        case "Instagram":
          if (this.state.instagramUsername != null) {
            //window.location.href = "https://instagram.com/" + this.state.instagramUsername;
            var url = "https://instagram.com/" + this.state.instagramUsername;
            window.open(url, "_blank");
          }
          break;
        case "YouTube":
          if (this.state.youtubeUsername != null) {
            //window.location.href = "https://www.youtube.com/user/" + this.state.youtubeUsername;
            var url =
              "https://www.youtube.com/user/" + this.state.youtubeUsername;
            window.open(url, "_blank");
          }
          break;
        case "Snapchat":
          if (this.state.snapchatUsername != null) {
            //window.location.href = "https://www.snapchat.com/add/"+ this.state.snapchatUsername;
            var url =
              "https://www.snapchat.com/add/" + this.state.snapchatUsername;
            window.open(url, "_blank");
          }
          break;
        case "Pinterest":
          if (this.state.pinterestUsername != null) {
            //window.location.href ="https://www.pinterest.com/"+ this.state.pinterestUsername;
            var url =
              "https://www.pinterest.com/" + this.state.pinterestUsername;
            window.open(url, "_blank");
          }
          break;
        default:
          break;
      }
    };

    render() {
      console.log(this.state?.mapCustoIsPremium);
      //Init Map Color with Premium check
      var backgroundColor = this.state?.mapCustoIsPremium
        ? this.state?.isPremium
          ? this.state.backgroundColor
          : "#424E6A"
        : this.state.backgroundColor;
      var seenColor = this.state?.mapCustoIsPremium
        ? this.state?.isPremium
          ? this.state.seenColor
          : "#C2D4E2"
        : this.state.seenColor;
      var unseenColor = this.state?.mapCustoIsPremium
        ? this.state?.isPremium
          ? this.state.unseenColor
          : "#FAFAFA"
        : this.state.unseenColor;
      //Init UserCard Color with Premium check
      var userCardTitleColor = this.state?.userCardIsPremium
        ? this.state?.isPremium
          ? this.state.userCardTitleColor
          : "#FAFEFC"
        : this.state.userCardTitleColor;
      var userCardNameColor = this.state?.userCardIsPremium
        ? this.state?.isPremium
          ? this.state.userCardNameColor
          : "#26FFA8"
        : this.state.userCardNameColor;
      var userCardColor1 = this.state?.userCardIsPremium
        ? this.state?.isPremium
          ? this.state.userCardColor1
          : "#0F0F0F"
        : this.state.userCardColor1;
      var userCardColor2 = this.state?.userCardIsPremium
        ? this.state?.isPremium
          ? this.state.userCardColor2
          : "#0F0F0F"
        : this.state.userCardColor2;

      var height = window.innerHeight;
      var width = (window.innerHeight * 700) / 320;
      var startingX = window.innerWidth > 720 ? 0 : width / 2;
      return (
        <div style={{ background: backgroundColor, flex: 0 }}>
          {this.props.previewMode ? null : (
            <div>
              {/* Socials Pannel */}
              <div
                style={{
                  position: "absolute",
                  top: 21,
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 30,
                  margin: "0 auto",
                  left: 21,
                  backgroundColor: "white",
                  borderRadius: 5,
                }}
                //onClick={this.onSocialClick("Tiktok")}
              >
                {this.state.tiktokUsername ? (
                  <div
                    className="bouton-social"
                    style={{ alignItems: "center", padding: 3 }}
                    onClick={() => this.onSocialClick("TikTok")}
                  >
                    <img
                      src="../../../assets/img/logoSocials/Logo_Tiktok.png"
                      alt="Tiktok Link"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {this.state.instagramUsername ? (
                  <div
                    className="bouton-social"
                    style={{ alignItems: "center", padding: 3 }}
                    onClick={() => this.onSocialClick("Instagram")}
                  >
                    <img
                      src="../../../assets/img/logoSocials/Logo_Instagram.png"
                      alt="Instagram Link"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {this.state.youtubeUsername ? (
                  <div
                    className="bouton-social"
                    style={{ alignItems: "center", padding: 3 }}
                    onClick={() => this.onSocialClick("YouTube")}
                  >
                    <img
                      src="../../../assets/img/logoSocials/Logo_Youtube.png"
                      alt="Youtube Link"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {this.state.snapchatUsername ? (
                  <div
                    className="bouton-social"
                    style={{ alignItems: "center", padding: 3 }}
                    onClick={() => this.onSocialClick("Snapchat")}
                  >
                    <img
                      src="../../../assets/img/logoSocials/Logo_Snapchat.png"
                      alt="Snapchat Link"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {this.state.pinterestUsername ? (
                  <div
                    className="bouton-social"
                    style={{ alignItems: "center", padding: 3 }}
                    onClick={() => this.onSocialClick("Pinterest")}
                  >
                    <img
                      src="../../../assets/img/logoSocials/Logo_Pinterest.png"
                      alt="Pinterest Link"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {/* Badge Panel */}
              <div
                style={{
                  position: "absolute",
                  top: 21,
                  alignSelf: "center",
                  zIndex: 20,
                  margin: "0 auto",
                  left: 0,
                  right: 0,
                }}
              >
                <div
                  style={{
                    alignSelf: "center",
                    flex: 0,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  {this.state.username ? (
                    <div
                      className="col-lg-2 col-md-4 col-sm-6 col-6 mx-auto"
                      style={{
                        zIndex: 1,
                        background: `linear-gradient(to right, ${userCardColor1}, ${userCardColor2})`,
                        // background: "red",
                        borderRadius: 34,
                        flexDirection: "row",
                        display: "flex",
                        padding: 5,
                      }}
                    >
                      {/* <div
                    className="icon icon-lg "
                    style={{ color: "rgba(255,255,255,0.5)" }}
                  >
                    <span className="fas fa-globe"></span>
                  </div> */}
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="badge"
                          id="likeButton"
                          onClick={this.onPressFunction}
                          style={{ marginRight: 8 }}
                        >
                          <i
                            className={` ${
                              this.state.clicked
                                ? "fas fa-heart fa-lg"
                                : "far fa-heart fa-lg animate-heart"
                            }`}
                          ></i>
                          <span className="like-count">
                            {like_format_helper(this.state?.mapLikeCounter)}
                          </span>
                        </div>
                        <div style={{ flexDirection: "row" }}>
                          <p
                            className="text-adaptive"
                            style={{
                              color: userCardTitleColor,
                              fontWeight: "600",
                              alignSelf: "center",
                            }}
                          >
                            {this.state?.userCardTitle}
                          </p>
                          <p
                            className="text-adaptive"
                            style={{
                              color: userCardNameColor,
                              fontWeight: "400",
                              margin: 0,
                              marginTop: -18,
                              fontSize: 15,
                            }}
                          >
                            by @{this.state?.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontSize: 12,
                  fontWeight: "600",
                  marginTop: 12,
                }}
              >{this.state.customName}</p> */}
              </div>
              {/* App Store Redirection */}
              <div
                className="icon icon-lg align-bottom-center"
                style={{
                  color: "rgba(255,255,255,0.5)",
                  position: "absolute",
                  bottom: 21,
                  left: 0,
                  right: 0,
                  zIndex: 20,
                  margin: "0 auto",
                }}
              >
                <a
                  href="https://apps.apple.com/fr/app/globe-travel-companion/id6451071849"
                  className="animated-button"
                >
                  👉 Create your own map
                </a>
              </div>
            </div>
          )}
          <TransformWrapper initialPositionX={-startingX}>
            <TransformComponent
              contentStyle={{ width: width, height: height }}
              wrapperStyle={{
                width: window.innerWidth,
                height: window.innerHeight,
              }}
            >
              <Chart
                width={width + "px"}
                height={height + "px"}
                chartType="GeoChart"
                data={this.state.countryList || []}
                options={{
                  backgroundColor: backgroundColor,
                  datalessRegionColor: unseenColor,
                  legend: "none",
                  defaultColor: seenColor,
                }}
                // Note: you will need to get a mapsApiKey for your project.
                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                mapsApiKey="YOUR_MAP_API_KEY_GOES_HERE"
                rootProps={{ "data-testid": "1" }}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      );
    }
  }
);
