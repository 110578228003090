export const like_format_helper = (likesCount) => {
    

    // Si le nombre de likes est inférieur ou égal à 1000, renvoyer tel quel
  if (likesCount <= 1000) {
    return likesCount.toString();
  }
  
  if (likesCount <= 999999) {
    const thousands = Math.floor(likesCount / 1000);
    const hundreds = Math.floor((likesCount % 1000) / 100);
    return `${thousands}k${hundreds}`;
  }

  // Si le nombre de likes est supérieur à 999999
  const millions = Math.floor(likesCount / 1000000);
  const hundredThousands = Math.floor((likesCount % 1000000) / 100000);
  return `${millions}M${hundredThousands}`;

  
  };