import "./App.css";
import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer py-5 pt-lg-6">
        <div className="sticky-right">
          <a
            href="#home"
            className="icon icon-primary icon-md btn btn-icon-only btn-white border border-soft shadow-soft animate-up-3"
          >
            <span className="fas fa-chevron-up"></span>
          </a>
        </div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <p>
                Blip is the best companion app for creators and influencers.
              </p>
              <ul className="social-buttons mb-5 mb-lg-0">
                <li>
                  <a
                    href="https://www.facebook.com/chellestudio.apps"
                    className="icon icon-md icon-facebook mr-3"
                    aria-label="facebook social link"
                  >
                    <span className="fab fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/ChelleStudio"
                    aria-label="github social link"
                    className="icon icon-md icon-github mr-3"
                  >
                    <span className="fab fa-github"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-2 mb-5 mb-lg-0">
              <span className="h5">Blip</span>
              <ul className="footer-links mt-2">
                <li>
                  <a
                    target="_blank"
                    href="https://chelle.studio/"
                    rel="noreferrer"
                  >
                    ChelleStudio
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/highlights-cover-maker/id1459962627#?platform=iphone"
                    rel="noreferrer"
                  >
                    Appstore Page
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://chelle.studio/"
                    rel="noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://chelle.studio/contact-us/"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">Subscribe</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control mb-2"
                      placeholder="example@company.com"
                      name="email"
                      aria-label="Subscribe form"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-dark shadow-soft btn-block"
                      data-loading-text="Sending"
                    >
                      <span>Subscribe</span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">No spam. Pinky swear.</p>
            </div>
          </div>
          <hr className="bg-light my-2" />
          <div className="row pt-2 pt-lg-5">
            <div className="col mb-md-0">
              <a
                href="https://Blip.com"
                target="_blank"
                className="d-flex justify-content-center"
                rel="noreferrer"
              >
                <img
                  src="/assets/img/dark.svg"
                  height="50"
                  className="mb-3 round-icon shadow-lg"
                  alt="Blip Logo"
                />
              </a>
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Blip 2019-
                  <span className="current-year">2022</span>. All rights
                  reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
