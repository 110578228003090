import "./App.css";
import React from "react";
import { withParams } from "./hocs";
import Header from "./Header";
import Footer from "./Footer";

import "./scss/swipe.scss";
class Landing extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <main className="test">
          <section className="section section-header text-dark pb-md-8">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 text-center mb-5 mb-md-7">
                  <h1 className="display-2 font-weight-bolder mb-4">
                    Blip, the ideal creator's companion
                  </h1>
                  <p className="lead mb-4 mb-lg-5">
                    Blip elevates your branding and gives you the tools to reach
                    more people. <br></br>Currently only available on iOS for
                    free.
                  </p>
                  <div>
                    <a
                      href="https://apps.apple.com/us/app/highlights-cover-maker/id1459962627#?platform=iphone"
                      className="btn btn-dark btn-download-app mb-xl-0 mr-2 mr-md-3"
                    >
                      <span className="d-flex align-items-center">
                        <span className="icon icon-brand mr-2 mr-md-3">
                          <span className="fab fa-apple"></span>
                        </span>
                        <span className="d-inline-block text-left">
                          <small className="font-weight-normal d-none d-md-block">
                            Available on
                          </small>{" "}
                          App Store
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-10 justify-content-center">
                  <img
                    className="d-none d-md-inline-block"
                    src={"./assets/img/illustrations/scene.svg"}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="section section-lg py-0">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="card border-0 bg-white text-center p-1">
                    <div className="card-header bg-white border-0 pb-0">
                      <div className="icon icon-lg  mb-4">
                        <span className="fas fa-money-bill-wave"></span>
                      </div>
                      <h2 className="h3 text-dark m-0">Brand Better</h2>
                    </div>
                    <div className="card-body">
                      <p>
                        Improve your branding with our unique collections of
                        story higlight covers for Instagram.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="card border-0 bg-white text-center p-1">
                    <div className="card-header bg-white border-0 pb-0">
                      <div className="icon icon-lg icon mb-4">
                        <span className="fas fa-map-marked-alt"></span>
                      </div>
                      <h2 className="h3 text-dark m-0">Connect better</h2>
                    </div>
                    <div className="card-body">
                      <p>
                        Get your own dedicated page to share all your important
                        links with your followers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="card border-0 bg-white text-center p-1">
                    <div className="card-header bg-white border-0 pb-0">
                      <div className="icon icon-lg icon mb-4">
                        <span className="fas fa-shopping-basket"></span>
                      </div>
                      <h2 className="h3 text-dark m-0">Partner better</h2>
                    </div>
                    <div className="card-body">
                      <p>
                        Create your own business contact page. We'll filter out
                        spam and let you focus on real opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section section-lg" id="about">
            <div className="container">
              <div className="row justify-content-center mb-5 mb-lg-7">
                <div className="col-12 col-lg-8 text-center">
                  <h2 className="h1 mb-4">Better in every way</h2>
                  <p className="lead">
                    From our intuitive experience to our stellar results, Blip
                    is simply the best. We collaborated with brands, influencers
                    and small buisnesses and never had disappointing results.
                  </p>
                </div>
              </div>
              <div className="row row-grid align-items-center mb-5 mb-lg-7">
                <div className="col-12 col-lg-5">
                  <h2 className="mb-4">A new way to branding</h2>
                  <p>
                    At Blip, we rethink how personal branding should be
                    approached. A cross platform branding is essential to offer
                    a meaningul experience to your followers.
                  </p>
                  <p>
                    From our page builders to our icons customizer, we empower
                    you with the right tools to build a community.
                  </p>
                  <a href="#" className="btn btn-dark mt-3 animate-up-2">
                    Learn More
                    <span className="icon icon-xs ml-2">
                      <i className="fas fa-external-link-alt"></i>
                    </span>
                  </a>
                </div>
                <div className="col-12 col-lg-6 ml-lg-auto">
                  <img
                    src={"/assets/img/illustrations/scene-3.svg"}
                    className="w-100"
                    alt=""
                  />
                </div>
              </div>
              <div className="row row-grid align-items-center mb-5 mb-lg-7">
                <div className="col-12 col-lg-5 order-lg-2">
                  <h2 className="mb-4">Get it. It's free.</h2>
                  <p>
                    Our app is for anyone. Whether you start your creator
                    adventure or you've been on the journey for a while, Blip
                    will soon become your favorite app.
                  </p>
                  <a href="#" className="btn btn-dark mt-3 animate-up-2">
                    Learn More
                    <span className="icon icon-xs ml-2">
                      <i className="fas fa-external-link-alt"></i>
                    </span>
                  </a>
                </div>
                <div className="col-12 col-lg-6 mr-lg-auto">
                  <img
                    src="./assets/img/illustrations/scene-2.svg"
                    className="w-100"
                    alt=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="card border-light p-4">
                    <div className="card-body">
                      <h2 className="display-2 mb-2">4.9/5</h2>
                      <span>Average satisfaction over 4.5k+ ratings.</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="card border-light p-4">
                    <div className="card-body">
                      <h2 className="display-2 mb-2">200k+</h2>
                      <span>App installs from the Apple Store.</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="card border-light p-4">
                    <div className="card-body">
                      <h2 className="display-2 mb-2">24/7</h2>
                      <span>
                        Our support team is a quick chat or email away.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section section-lg pb-0" id="testimonials">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 text-center mb-5 mb-lg-6">
                  <h2 className="display-3 mb-4">Creators love it</h2>
                  <p className="lead">
                    The final result of our formula at work. Check out what our
                    users <br className="d-none d-lg-inline-block" /> have to
                    say about our mobile app and our support team.
                  </p>
                </div>
              </div>
              <div className="row mt-lg-6">
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="card border-light">
                    <div className="card-body text-center py-5">
                      <h6>Atiqah Ghaffar</h6>
                      <span className="d-block">
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                      </span>
                      <p className="px-2 my-4">
                        Easy to use and all the icons are cute! Thanks for this
                        application ♥️ I have been searching the best that meet
                        my expectation and here it is. No need to do manually 😋
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="card border-light mt-lg-n6">
                    <div className="card-body text-center py-5">
                      <h6>katilla_M</h6>
                      <span className="d-block">
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star far fa-star text-warning"></span>
                      </span>
                      <p className="px-2 my-4">
                        Hay una infinidad de opciones, colores básicos pero se
                        pueden hacer muchas combinaciones muy útil
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="card border-light">
                    <div className="card-body text-center py-5">
                      <h6>Arianna Jeanette</h6>
                      <span className="d-block">
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                      </span>
                      <p className="px-2 my-4">
                        Love it! Very easy to use and beautiful options.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 mt-lg-4">
                  <div className="card border-light">
                    <div className="card-body text-center py-5">
                      <h6>Trust_timm</h6>
                      <span className="d-block">
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                      </span>
                      <p className="px-2 my-4">Best app for covers by far.</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 mt-lg-n5">
                  <div className="card border-light">
                    <div className="card-body text-center py-5">
                      <h6>LagniappeTheatre</h6>
                      <span className="d-block">
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star far fa-star text-warning"></span>
                      </span>
                      <p className="px-2 my-4">
                        Adorable. Easy to use and effective! They have a lot of
                        options but I still wish they had more icon options.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mt-lg-4">
                  <div className="card border-light">
                    <div className="card-body text-center py-5">
                      <h6>Mightystar03</h6>
                      <span className="d-block">
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star fas fa-star text-warning"></span>
                        <span className="star far fa-star text-warning"></span>
                      </span>
                      <p className="px-2 my-4">
                        Super easy to use, but I was hoping it had more icon
                        choices. Holidays and seasons icons would be ideal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="section bg-soft my-5" id="download">
            <figure className="position-absolute top-0 left-0 w-100 d-none d-md-block mt-n3"></figure>
            <div className="container">
              <div className="row row-grid align-items-center">
                <div className="col-12 col-lg-6">
                  <span className="h5 text-muted mb-2 d-block">
                    Download App
                  </span>
                  <h2 className="display-3 mb-4">Get started in seconds</h2>
                  <p className="lead text-muted">
                    Access our customization tools with thousands of icons and
                    our profile page builder in a matter of seconds. You don't
                    even need to create an account.
                  </p>
                  <div className="mt-4 mt-lg-5">
                    <a
                      href="https://apps.apple.com/us/app/highlights-cover-maker/id1459962627#?platform=iphone"
                      className="btn btn-dark btn-download-app mb-xl-0 mr-2 mr-md-3"
                    >
                      <span className="d-flex align-items-center">
                        <span className="icon icon-brand mr-2 mr-md-3">
                          <span className="fab fa-apple"></span>
                        </span>
                        <span className="d-inline-block text-left">
                          <small className="font-weight-normal d-none d-md-block">
                            Available on
                          </small>{" "}
                          App Store
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5 ml-lg-auto">
                  <img
                    className="d-none d-lg-inline-block"
                    src="./assets/img/illustrations/scene-3.svg"
                    alt="Mobile App Illustration"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withParams(Landing);
